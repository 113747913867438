import { FC } from 'react';
import { Badge } from '@atoms/Badge/index';
import { Card, Text, Flex, Stack, Title } from '@mantine/core';
import { NEWS_HEADER_IMAGE } from 'src/mockdata';
import { useStyles } from './styles';
import { NewsCardProps } from './types';
import Link from 'next/link';
import Image from 'next/image';

export const NewsCard: FC<NewsCardProps> = ({
  news,
  badgeVariant,
  titleOrder,
  hasBadge,
  hasDescription = true,
  hasSubtitle,
  titleVariant,
}) => {
  const { classes } = useStyles();

  const { category, header_image, title, sub_title, slug, description_text } =
    news;

  return (
    <Card className={classes.newsCard} pt={0}>
      {header_image && (
        <Link
          href={`/noticias/${category?.slug}/${slug}`}
          style={{ textDecoration: 'none', width: '100%' }}
        >
          <Card.Section mb={12} className={classes.imageSection}>
            <Flex
              pos="relative"
              w="100%"
              h={{
                base: titleVariant === 'h1' ? 400 : 300,
                lg: titleVariant === 'h1' ? 400 : 200,
              }}
            >
              <Image
                src={header_image.key ?? NEWS_HEADER_IMAGE}
                alt={title}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                fill
                style={{
                  objectFit: 'cover',
                }}
                priority
              />
            </Flex>
          </Card.Section>
        </Link>
      )}
      <Card.Section sx={{ wordBreak: 'break-word' }}>
        <Stack spacing={4}>
          {hasBadge && category && (
            <Link
              href={`/noticias/${category?.slug}`}
              style={{ textDecoration: 'none', width: '100%' }}
              aria-label={`link-${category.name}`}
            >
              <Badge
                variant={badgeVariant}
                radius="none"
                color={category.color}
              >
                {category.name}
              </Badge>
            </Link>
          )}
          <Link
            href={`/noticias/${category?.slug}/${slug}`}
            style={{ textDecoration: 'none', width: '100%' }}
            aria-label={`link-${title}`}
          >
            <Title
              my={4}
              order={titleOrder}
              variant={titleVariant}
              className={classes.titleSection}
            >
              {title}
            </Title>
          </Link>
          {hasSubtitle && (
            <Link
              href={`/noticias/${category?.slug}/${slug}`}
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <Text
                className={classes.subtitleSection}
                mt={4}
                variant="body"
                lineClamp={2}
                component="p"
              >
                {sub_title}
              </Text>
            </Link>
          )}
          {hasDescription && description_text && (
            <Text
              className={classes.subtitleSection}
              mt={2}
              variant="body"
              lineClamp={3}
              component="p"
              dangerouslySetInnerHTML={{ __html: description_text.text }}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
};
