import { FC } from 'react';
import { Badge as MantineBadge } from '@mantine/core';
import { BadgeProps } from './types';
import { badgeColorMap } from '@utils/badges';

export const Badge: FC<BadgeProps> = ({
  variant = 'filled',
  children,
  color,
  radius,
}) => {
  return (
    <MantineBadge
      style={{
        backgroundColor: badgeColorMap[color ?? 'BADGE_DEFAULT_COLOR'],
        textShadow: '0px 0px 1px rgba(0, 0, 0, 0.5)',
        lineHeight: 1,
        border: 0,
      }}
      radius={radius}
      variant={variant}
    >
      {children}
    </MantineBadge>
  );
};
