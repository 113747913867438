import { QUERY_KEY } from '@constants/queries';
import { GET } from '@lib/axios';
import { useQuery } from '@tanstack/react-query';
import { PublicSettingSEO } from '@utils/typesPages/types';

export const fetchSEO = async () => {
  const key = 'SEO';

  const { data: response } = await GET<PublicSettingSEO>(
    `/api/public-settings/${key}`,
    {
      params: { key: key },
    }
  );

  return response;
};

export const useFetchSEO = () => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_SEO],
    queryFn: fetchSEO,
    refetchInterval: 300000,
    gcTime: 300000,
  });
};
