import { QUERY_KEY } from '@constants/queries';

import { useQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import {
  PublicPublicityFilters,
  ApiListResponse,
  PublicityPublic,
} from '@utils/typesPages/types';

export const fetchPublicity = async (params: PublicPublicityFilters) => {
  const { data: response } = await GET<ApiListResponse<PublicityPublic[]>>(
    '/api/publicity',
    { params }
  );

  return response;
};

export const useFetchPublicity = (params: PublicPublicityFilters) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_PUBLICITY, params],
    queryFn: () => fetchPublicity(params),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};
