import { MUTATION_KEY } from '@constants/mutations/queries';

import { PATCH } from '@lib/axios';
import { useMutation } from '@tanstack/react-query';

const viewPublicity = async (id: string) => {
  const { data: response } = await PATCH(`/api/publicity/${id}/view`);

  return response;
};

export const useViewPublicity = () => {
  return useMutation({
    mutationKey: [MUTATION_KEY.VIEW_PUBLICITY],
    mutationFn: viewPublicity,
  });
};
