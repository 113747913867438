import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  newsCard: {
    width: '100%',
    borderRadius: 0,
    background: 'transparent',
  },
  imageSection: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  subtitleSection: {
    '&:hover': {
      cursor: 'default',
    },
  },
  titleSection: {
    '&:hover': {
      color: theme.colors.primary[0],
      cursor: 'pointer',
    },
  },
}));
