import { z } from 'zod';

export type BadgeColorOptions =
  | 'BADGE_BLUE'
  | 'BADGE_GREEN'
  | 'BADGE_LIGHT_GREEN'
  | 'BADGE_ORANGE'
  | 'BADGE_YELLOW'
  | 'BADGE_PINK'
  | 'BADGE_DARK_RED'
  | 'BADGE_WINE'
  | 'BADGE_MEDIUM_BLUE'
  | 'BADGE_LIGHT_BLUE'
  | 'BADGE_PURPLE'
  | 'BADGE_DEFAULT_COLOR';

export const BadgeColorOptionsSchema = z.union([
  z.literal('BADGE_BLUE'),
  z.literal('BADGE_GREEN'),
  z.literal('BADGE_LIGHT_GREEN'),
  z.literal('BADGE_ORANGE'),
  z.literal('BADGE_YELLOW'),
  z.literal('BADGE_PINK'),
  z.literal('BADGE_DARK_RED'),
  z.literal('BADGE_WINE'),
  z.literal('BADGE_MEDIUM_BLUE'),
  z.literal('BADGE_LIGHT_BLUE'),
  z.literal('BADGE_PURPLE'),
  z.literal('BADGE_DEFAULT_COLOR'),
]);

export const badgeColorMap: { [key in BadgeColorOptions]: string } = {
  BADGE_BLUE: '#0a1ea0', // Azul escuro
  BADGE_GREEN: '#0f554b', // Verde escuro
  BADGE_LIGHT_GREEN: '#96faa5', // Verde claro
  BADGE_YELLOW: '#d9ba09', // Amarelo
  BADGE_ORANGE: '#ff8705', // Laranja
  BADGE_PINK: '#fa4b78', // Rosa
  BADGE_DARK_RED: '#b90532', // Vermelho escuro
  BADGE_WINE: '#690537', // Vinho
  BADGE_MEDIUM_BLUE: '#646ef0', // Azul médio
  BADGE_LIGHT_BLUE: '#46bee1', // Azul claro
  BADGE_PURPLE: '#646ef0', // Purple
  BADGE_DEFAULT_COLOR: '#0a1ea0',
};

export const badgeColorArray: BadgeColorOptions[] = [
  'BADGE_BLUE',
  'BADGE_GREEN',
  'BADGE_LIGHT_GREEN',
  'BADGE_ORANGE',
  'BADGE_YELLOW',
  'BADGE_PINK',
  'BADGE_DARK_RED',
  'BADGE_WINE',
  'BADGE_MEDIUM_BLUE',
  'BADGE_LIGHT_BLUE',
  'BADGE_PURPLE',
];

export const badgeZodArray: BadgeColorOptions[] = [
  'BADGE_BLUE',
  'BADGE_GREEN',
  'BADGE_LIGHT_GREEN',
  'BADGE_ORANGE',
  'BADGE_YELLOW',
  'BADGE_PINK',
  'BADGE_DARK_RED',
  'BADGE_WINE',
  'BADGE_MEDIUM_BLUE',
  'BADGE_LIGHT_BLUE',
  'BADGE_PURPLE',
  'BADGE_DEFAULT_COLOR',
];

export const badgeColorMapTranslations: { [key in BadgeColorOptions]: string } =
  {
    BADGE_BLUE: 'BADGE.BLUE', // Azul escuro
    BADGE_GREEN: 'BADGE.GREEN', // Verde escuro
    BADGE_LIGHT_GREEN: 'BADGE.LIGHT_GREEN', // Verde claro
    BADGE_YELLOW: 'BADGE.YELLOW', // Amarelo
    BADGE_ORANGE: 'BADGE.ORANGE', // Laranja
    BADGE_PINK: 'BADGE.PINK', // Rosa
    BADGE_DARK_RED: 'BADGE.DARK_RED', // Vermelho escuro
    BADGE_WINE: 'BADGE.WINE', // Vinho
    BADGE_MEDIUM_BLUE: 'BADGE.MEDIUM_BLUE', // Azul médio
    BADGE_LIGHT_BLUE: 'BADGE.LIGHT_BLUE', // Azul claro
    BADGE_PURPLE: 'BADGE.PURPLE', // Vinho (usado como roxo)
    BADGE_DEFAULT_COLOR: 'BADGE.DEFAULT_COLOR', // Cor Padrão
  };
