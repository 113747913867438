import { Flex, Text } from '@mantine/core';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useStyles } from '@molecules/frontoffice/Header/styles';

export const EmptyStateComponent = () => {
  const { t } = useTranslation('common');
  const { theme } = useStyles();

  return (
    <Flex
      direction="column"
      h="30vw"
      w="100%"
      justify="center"
      align="center"
      gap={4}
    >
      <XCircleIcon
        height={46}
        width={46}
        color={
          theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[6]
        }
      />
      <Text variant="footerTitle" component="span">
        {t('common.noDataFound')}
      </Text>
    </Flex>
  );
};
