import { useStyles } from '@styles/authorsPage/styles';

export const TitleUnderline = () => {
  const { theme } = useStyles();

  return (
    <div
      style={{
        width: 50,
        height: 4,
        background: theme.colors.primary[0],
      }}
    />
  );
};
