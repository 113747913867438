import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  newsParent: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
    '&:hover > *': {
      color: theme.fn.darken(theme.colors.primary[0], 0.05),
    },
  },
}));
