import { PublicityCard } from '@organisms/frontoffice/PublicityCard';
import { PublicityType } from '@pages/api/types';
import { PublicityPublic } from '@utils/typesPages/types';

// Transforms index into an index inside of arrayLenght
const transformIndex = (index: number, arrayLength: number) => {
  const newIndex = index % arrayLength;

  return newIndex;
};

export const RandomPublicityComponent = ({
  publicities,
  index,
  type,
}: {
  type: PublicityType;
  index: number;
  publicities: PublicityPublic[];
}) => {
  const publicitiesType = publicities.filter(
    (publicity) => publicity.type === type
  );

  const indexPublicity = transformIndex(index, publicitiesType.length);

  return publicitiesType[indexPublicity] ? (
    <PublicityCard publicity={publicitiesType[indexPublicity]} />
  ) : null;
};
