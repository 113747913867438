import { FETCH_LIMIT_CATEGORY_NEWS } from '@constants/pagination';
import { QUERY_KEY } from '@constants/queries';

import { QueryClient, useQuery } from '@tanstack/react-query';

import { fetchNews } from './useFetchNews';

import { fetchCategories } from './useFetchCategories';

export const fetchNewsByCategory = async () => {
  const newsQueries = [];

  const queryClient = new QueryClient();

  const categories = await fetchCategories({ fetchAll: true });

  for (const category of categories.data) {
    const categorySlug = category.slug;

    const newsQueryKey = [
      QUERY_KEY.FETCH_NEWS,
      { limit: FETCH_LIMIT_CATEGORY_NEWS, category: categorySlug },
    ];

    const newsQuery = queryClient.getQueryData(newsQueryKey);

    if (!newsQuery) {
      newsQueries.push(
        queryClient.fetchQuery({
          queryKey: newsQueryKey,
          queryFn: () =>
            fetchNews({
              limit: FETCH_LIMIT_CATEGORY_NEWS,
              category: categorySlug,
            }),
        })
      );
    }
  }

  const response = await Promise.all(newsQueries);

  return response;
};

export const useFetchNewsByCategory = (categories: string[]) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_NEWS_BY_CATEGORY],
    queryFn: () => fetchNewsByCategory(),
    enabled: categories.length > 0,
  });
};
