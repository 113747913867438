import { FC } from 'react';
import { PublicityCardProps } from './types';
import { Flex, Text, Title, Button } from '@mantine/core';
import Link from 'next/link';
import { NEWS_HEADER_IMAGE } from 'src/mockdata';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { InView } from 'react-intersection-observer';
import { useViewPublicity } from '@hooks/mutations/useViewPublicity';

export const PublicityCard: FC<PublicityCardProps> = ({ publicity }) => {
  const { t } = useTranslation('common');

  const { mutate: viewPublicity } = useViewPublicity();

  if (!publicity) {
    return null;
  }

  const url = `/api/publicity/${publicity.id}/count`;

  const handleInView = () => {
    viewPublicity(publicity.id);
  };

  if (publicity.type === 'BANNER') {
    return (
      <>
        <InView
          style={{ padding: 0, margin: 0 }}
          onChange={(isInView) => {
            if (isInView) {
              handleInView();
            }
          }}
          triggerOnce={true}
          threshold={0.5}
        >
          {({ ref }) => {
            return <div ref={ref} />;
          }}
        </InView>
        <Link
          href={url}
          target="_blank"
          style={{ width: '100%', height: 300, position: 'relative' }}
        >
          <Image
            fill
            priority
            src={publicity?.image?.key ?? NEWS_HEADER_IMAGE}
            alt={publicity.title}
            style={{ objectFit: 'cover' }}
          />
        </Link>
      </>
    );
  }

  if (publicity.type === 'NEWS') {
    return (
      <>
        <InView
          style={{ padding: 0, margin: 0 }}
          onChange={(isInView) => {
            if (isInView) {
              handleInView();
            }
          }}
          triggerOnce={true}
          threshold={0.5}
        >
          {({ ref }) => {
            return <div ref={ref} />;
          }}
        </InView>
        <Flex direction="column" maw={400}>
          <Title variant="h3Orange">{publicity.title}</Title>
          <Text variant="body" mt={12} component="p">
            {publicity.description}
          </Text>
          <Link target="_blank" style={{ marginTop: 16 }} href={url}>
            <Button variant="tertiary">{t('common.knowMore')}</Button>
          </Link>
        </Flex>
      </>
    );
  }

  return (
    <>
      <InView
        style={{ padding: 0, margin: 0 }}
        onChange={(isInView) => {
          if (isInView) {
            handleInView();
          }
        }}
        triggerOnce={true}
        threshold={0.5}
      >
        {({ ref }) => {
          return <div ref={ref} />;
        }}
      </InView>
      <Link target="_blank" href={url}>
        <Image
          sizes="100vw"
          width={1920}
          height={1080}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          src={publicity?.image?.key ?? NEWS_HEADER_IMAGE}
          alt={publicity.title}
        />
      </Link>
    </>
  );
};
