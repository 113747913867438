import { FC } from 'react';
import { Flex, Text } from '@mantine/core';
import { formatLastNews } from '@utils/dates';
import Link from 'next/link';
import { useStyles } from './styles';
import { InlineNewsProps } from './types';

export const InlineNews: FC<InlineNewsProps> = ({ news }) => {
  const { classes } = useStyles();
  const date = new Date(news?.publish_at ?? '');

  const formattedDate = formatLastNews(date);

  if (!formattedDate) {
    return null;
  }

  return (
    <Link
      href={`/noticias/${news?.category.name}/${news?.slug}`}
      style={{ textDecoration: 'none' }}
    >
      <Flex
        mt={4}
        className={classes.newsParent}
        sx={{ wordBreak: 'break-word' }}
      >
        <Text miw={50} variant="caption1Orange" component="span">
          {formattedDate}
        </Text>
        <Text variant="body" component="span" lineClamp={2}>
          {news?.title}
        </Text>
      </Flex>
    </Link>
  );
};
